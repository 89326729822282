<template lang="pug">
.hello
  .logo
    .frame
      .school-peeps
    .name
      .tutor tutor
      .depot depot
    .tagline equal education for
     .all &nbsp;all
</template>
<script>
export default {
  name: 'hello'
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/scss/global';
.hello {
  position: absolute;
  left: 5rem;
  top: 1rem;
  //transform: scale(1.2);
  //transform: scale(3);
  //@include s-laptop {
  //}
  //@include s-iphones {
  //  transform: scale(0.4);
  //  @media (orientation : landscape) {
  //    transform: scale(0.7) translate3d(0, -4rem, 0);
  //  }
  //}
  transform-origin: top left;
  .logo {
    position: relative;
    display: grid;
    grid-template-rows: 7rem 3rem;
    .name {
      //font-size: 1.1rem;
      //padding: 0.25rem;
      position: relative;
      .tutor {
        position: absolute;
        top: -6.5rem;
        left: -3rem;
        font-size: 2rem;
      }
      .depot {
        position: absolute;
        top: -3.5rem;
        left: 6rem;
        font-size: 2rem;
        text-transform: uppercase;
        color: #989898;
      }
    }
    .tagline {
      position: absolute;
      top: 100%;
      left: 0;
      transform: translate3d(0.25rem, -3.5rem, 0);
      white-space: nowrap;
      color: #989898;
      font-weight: bold;
      .all {
        display: inline-block;
        //color: black;
      }
    }
  }
  .frame {
    box-sizing: border-box;
    padding: 3rem;
    position: relative;
    width: 6rem;
    .school-peeps {
      position: absolute;
      top: 28%;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 4rem;
      height: 4rem;
    }
  }
}
</style>
