<template lang="pug">
hello
</template>
<script>
import hello from '@/components/hello.vue';
export default {
  name: 'app',
  components: { hello }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  .frame {
    background: no-repeat center
      url("data:image/svg+xml;charset%3Dutf8,%3Csvg id%3D'svg' xmlns%3D'http://www.w3.org/2000/svg' xmlns:xlink%3D'http://www.w3.org/1999/xlink' width%3D'400' height%3D'400' viewBox%3D'0, 0, 400,400'%3E%3Cg id%3D'svgg'%3E%3Cpath id%3D'path0' d%3D'M108.833 110.829 L 18.667 200.990 18.667 290.495 L 18.667 380.000 199.333 380.000 L 380.000 380.000 380.000 290.494 L 380.000 200.988 289.997 110.994 C 240.496 61.497,199.771 20.925,199.497 20.834 C 199.224 20.743,158.425 61.241,108.833 110.829 M284.832 120.499 L 370.000 205.665 370.000 287.832 L 370.000 370.000 199.333 370.000 L 28.667 370.000 28.667 287.834 L 28.667 205.669 113.832 120.501 C 160.673 73.659,199.148 35.333,199.331 35.333 C 199.515 35.333,237.990 73.658,284.832 120.499 ' stroke%3D'none' fill%3D'%23000000' fill-rule%3D'evenodd'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
  }
  .school-peeps {
    background: no-repeat center
      url("data:image/svg+xml;charset%3Dutf8,%3Csvg id%3D'svg' xmlns%3D'http://www.w3.org/2000/svg' xmlns:xlink%3D'http://www.w3.org/1999/xlink' width%3D'400' height%3D'400' viewBox%3D'0, 0, 400,400'%3E%3Cg id%3D'svgg'%3E%3Cpath id%3D'path0' d%3D'M217.405 37.280 C 206.754 49.048,207.223 77.394,218.232 87.357 C 240.795 107.776,276.190 91.726,276.190 61.077 C 276.190 31.519,236.982 15.647,217.405 37.280 M65.024 75.375 C 44.605 97.937,60.655 133.333,91.304 133.333 C 120.862 133.333,136.734 94.125,115.101 74.548 C 103.333 63.897,74.987 64.365,65.024 75.375 M254.762 103.030 C 233.648 107.917,169.048 159.628,169.048 171.643 C 169.048 188.002,182.690 188.981,204.992 174.223 C 230.125 157.590,231.864 158.199,240.292 186.579 C 250.594 221.269,250.323 223.032,223.899 293.538 C 198.602 361.036,197.968 371.429,219.151 371.429 C 231.765 371.429,233.951 368.025,257.775 311.295 C 278.504 261.932,283.908 252.664,287.942 259.552 C 307.309 292.617,312.424 295.238,357.566 295.238 L 400.000 295.238 400.000 281.508 C 400.000 265.687,391.825 262.073,355.803 261.970 C 329.610 261.895,330.523 263.335,324.022 211.905 C 321.374 190.952,317.790 168.761,316.059 162.590 C 313.089 152.007,314.415 152.369,339.409 168.965 C 369.040 188.638,383.333 189.499,383.333 171.609 C 383.333 143.292,291.784 94.460,254.762 103.030 M29.762 168.299 C 9.608 187.563,0.000 200.396,0.000 208.047 C 0.000 227.691,13.377 226.684,35.057 205.407 C 59.141 181.770,64.132 181.117,70.963 200.711 C 79.142 224.172,77.429 236.186,58.714 286.640 C 42.888 329.306,41.939 334.456,48.659 341.176 C 61.189 353.706,74.715 344.947,85.039 317.616 C 105.379 263.767,104.810 264.810,110.653 270.653 C 114.068 274.068,133.167 276.190,160.476 276.190 L 204.762 276.190 204.762 261.905 L 204.762 247.619 174.091 247.619 C 138.468 247.619,139.559 248.703,133.726 207.513 C 126.827 158.799,117.201 145.839,85.470 142.544 L 59.524 139.851 29.762 168.299 ' stroke%3D'none' fill%3D'%23000000'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
  }
}
</style>
